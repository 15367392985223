import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";
import store from "@/store";

const firebaseConfig = {
  apiKey: "AIzaSyAsgALGnngWH4NB5wEikF-t8hCeUxVr08E",
  authDomain: "lexington-manor-apartments.firebaseapp.com",
  databaseURL: "https://lexington-manor-apartments.firebaseio.com",
  projectId: "lexington-manor-apartments",
  storageBucket: "lexington-manor-apartments.appspot.com",
  messagingSenderId: "139388336734",
  appId: "1:139388336734:web:32dc03391cb06373afd2cf",
  measurementId: "G-HCC00BE6QQ"
}

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const functions = firebase.functions();
var storage = firebase.storage();

auth.onAuthStateChanged(user => {
  if (user) {
    user.getIdTokenResult().then(idTokenResult => {
      user.admin = idTokenResult.claims.admin;
    });

    store.dispatch("updateUser", user);
  } else {
    store.dispatch("updateUser", null);
  }

  store.dispatch("changeAuthReady");
});

export { db, auth, functions, storage };
